// Custom handler transplanted from the OpenLayers 5.3.3 library featureloader.js and grafted to our octopus code.
// This is intended to intercept XHR request between OL and geoserver to handle the responses.

// The official default handler doesn't have error handling, so there is no way to spy on them.
// The effect on loading spinner is that we can't emit a custom event to turn it off on 400/other Network errors,
//  as well as when the response is 200 but there is no data -> no map rerender -> no toggling off.

// This is a hack to implement our own handler while keeping it as identical as the default layer loading function
//  to ensure compatibility.

import { GeoJSON } from "ol/format";
const FormatType = {
  ARRAY_BUFFER: "arraybuffer",
  JSON: "json",
  TEXT: "text",
  XML: "xml",
};
/**
 * @param {string|FeatureUrlFunction} url Feature URL service.
 * @param {import("./format/Feature.js").default} format Feature format.
 * @param {function(this:import("./VectorTile.js").default, Array<import("./Feature.js").default>, import("./proj/Projection.js").default, import("./extent.js").Extent)|function(this:import("./source/Vector").default, Array<import("./Feature.js").default>)} success
 *     Function called with the loaded features and optionally with the data
 *     projection. Called with the vector tile or source as `this`.
 * @param {function(this:import("./VectorTile.js").default)|function(this:import("./source/Vector").default)} failure
 *     Function called when loading failed. Called with the vector tile or
 *     source as `this`.
 * @return {FeatureLoader} The feature loader.
 */
export function loadFeaturesXhr(url, format = new GeoJSON(), success, failure) {
  return (
    /**
     * @param {import("./extent.js").Extent} extent Extent.
     * @param {number} resolution Resolution.
     * @param {import("./proj/Projection.js").default} projection Projection.
     * @this {import("./source/Vector").default|import("./VectorTile.js").default}
     */
    function(extent, resolution, projection) {
      var xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        typeof url === "function" ? url(extent, resolution, projection) : url,
        true
      );
      if (format.getType() == FormatType.ARRAY_BUFFER) {
        xhr.responseType = "arraybuffer";
      }
      /**
       * @param {Event} event Event.
       * @private
       */
      xhr.onload = function(event) {
        // status will be 0 for file:// urls
        if (!xhr.status || (xhr.status >= 200 && xhr.status < 300)) {
          var type = format.getType();
          /** @type {Document|Node|Object|string|undefined} */
          var source;
          if (type == FormatType.JSON || type == FormatType.TEXT) {
            source = xhr.responseText;
          } else if (type == FormatType.XML) {
            source = xhr.responseXML;
            if (!source) {
              source = new DOMParser().parseFromString(
                xhr.responseText,
                "application/xml"
              );
            }
          } else if (type == FormatType.ARRAY_BUFFER) {
            source = /** @type {ArrayBuffer} */ (xhr.response);
          }
          if (source) {
            success.call(
              this,
              format.readFeatures(source, { featureProjection: projection }),
              format.readProjection(source),
              format.getLastExtent()
            );
          } else {
            // Your custom error handler here
            // Append things you need from the xhr request as needed
            failure.call(this, xhr.response);
          }
        } else {
        // Your custom error handler here
        // Append things you need from the xhr request as needed
          failure.call(this, xhr.response);
        }
      }.bind(this);
      /**
       * @private
       */
      xhr.onerror = function() {
        // Your custom error handler here
        // Append things you need from the xhr request as needed
        failure.call(this, xhr.response );
      }.bind(this);
      xhr.send();
    }
  );
}

/**
 * Create an XHR feature loader for a `url` and `format`. The feature loader
 * loads features (with XHR), parses the features, and adds them to the
 * vector source.
 * @param {string|FeatureUrlFunction} url Feature URL service.
 * @param {import("./format/Feature.js").default} format Feature format.
 * @return {FeatureLoader} The feature loader.
 * @api
 */
export function customXhr(url, format) {
  return loadFeaturesXhr(
    url,
    format,
    /**
     * @param {Array<import("./Feature.js").default>} features The loaded features.
     * @param {import("./proj/Projection.js").default} dataProjection Data
     * projection.
     * @this {import("./source/Vector").default|import("./VectorTile.js").default}
     */
    function(features, dataProjection) {
      var sourceOrTile = /** @type {?} */ (this);
      if (typeof sourceOrTile.addFeatures === "function") {
        /** @type {import("./source/Vector").default} */ (sourceOrTile).addFeatures(
          features
        );
      }
    },
    // Add custom callback error handler here
    // If you need information from the xhr response, expose it from the loadFeaturesXhr above.
    (err) => {
      console.error("No data", err);
      document.querySelector("#loader").dispatchEvent(new Event("loadend")); // ends loading
      // Don't add a blocking error handler, like an alert - because this gets called on every map movement as well.
    }
  );
}
