<template>
  <div class="sidebar-pane" :id="computedID">
    <h1 class="sidebar-header">
      {{ title }}
      <span class="sidebar-close">
        <icon-base icon-name="chevron-left">
          <icon-chevron-left></icon-chevron-left>
        </icon-base>
      </span>
    </h1>
    <slot />
  </div>
</template>


<script>
import IconBase from './icons/IconBase.vue'
import IconChevronLeft from './icons/IconChevronLeft.vue'

export default {
  components: {
    IconBase,
    IconChevronLeft,
  },
  props: {
    title: {
      type: String,
    },
    id: {
      type: String,
      required: false,
    },
  },
  computed: {
    computedID() {
      return this.id || this.title.toLowerCase().replace(' ', '-')
    },
  },
}
</script>

<style lang="scss">
  $active-color: #0a3d69;
  #sidebar{
    .sidebar-pane{
      .sidebar-header{
        background-color: $active-color;
      }
    }
  }
</style>
