import { LAYER_HIERARCHY, FIELD_NAMES } from "./constants";

export const validation = {
  methods: {
    sanitizeFilterConfigurations: sanitizeFilterConfigurations,
  },
};

/**
 * Handler function to sanitize the imported JSON filter configuration.
 * @param {object} configurations
 * @returns
 */
export function sanitizeFilterConfigurations(
  configurations,
  initialConfigurations,
  filename='',
) {
  let validConfigurations = initialConfigurations;
  const validLayerLabels = getValidLabels();
  let validConfigurationLabels = [];
  let invalidConfigurationLabels = [];
  let validNonEmptyConfigurationLabels = [];
  Object.keys(configurations).forEach((key) => {
    if (
      // Rule 1: label must match LAYER_HIERARCHY labels - skip if not.
      validLayerLabels.includes(key) &&
      // Rule 2: layer can NOT have empty configuration.
      // Rule 3: if there's a configuration, it must have correct configuration structure.
      (isConfigurationEmpty(configurations[key])
        ? true
        : validateConfiguration(configurations[key]))
    ) {
      validConfigurationLabels = [...validConfigurationLabels, key];
      if (!isConfigurationEmpty(configurations[key]))
        validNonEmptyConfigurationLabels = [
          ...validNonEmptyConfigurationLabels,
          key,
        ];
      // const sanitizedChildren = sanitizedChildren
      // Add configs that passes all criteria.
      validConfigurations = {
        ...validConfigurations,
        [key]: configurations[key],
      };
    } else {
      console.error(`${key} configuration is not valid`); // Throw an error and catch this to do something about it.
      invalidConfigurationLabels = [...invalidConfigurationLabels, key]
    }
  });
  if (invalidConfigurationLabels.length > 0) {
    const errorMessage = `${invalidConfigurationLabels[0]} configuration is not valid. ${filename} cannot be loaded.`;
    alert(errorMessage);
    throw new Error(errorMessage);
  } 
  console.debug("Applying configurations for layers", validConfigurationLabels);
  return [
    validNonEmptyConfigurationLabels,
    validConfigurations,
  ];
}

/**
 * Validates the configuration object.
 * @param {object} configuration
 * @returns boolean
 */
function validateConfiguration(configuration) {
  if (
    !validateLogicalOperator(configuration) ||
    !configuration.children ||
    !validateChildren(configuration.children)
  )
    return false;
  return true;
}

// Helper function to check if object is empty.
export function isConfigurationEmpty(obj) {
  return (
    obj && // null and undefined check
    obj.children &&
    obj.children.length === 0
  );
}
// Get valid layer labels from LAYER_HIERARCHY
function getValidLabels() {
  let validLabelsArray = [];
  LAYER_HIERARCHY.forEach((layerObject) => {
    if (Array.isArray(layerObject.layers) && layerObject.layers.length > 0) {
      layerObject.layers.forEach((layer) => {
        if (Array.isArray(layer.layers) && layer.layers.length > 0) {
          layer.layers.forEach((nestedLayer) => {
            if (typeof nestedLayer.layers === "string")
              validLabelsArray.push(nestedLayer.layers);
          });
        } else if (typeof layer.layers === "string")
          validLabelsArray.push(layer.layers);
      });
    } else if (typeof layerObject.layers === "string")
      validLabelsArray.push(layerObject.layers);
  });
  return validLabelsArray;
}

// Must have logicalOperator 'any' || 'all'
function validateLogicalOperator(configuration) {
  return (
    (configuration.hasOwnProperty("logicalOperator") &&
      typeof configuration.logicalOperator === "string" &&
      configuration.logicalOperator === "any") ||
    configuration.logicalOperator === "all"
  );
}

// All-or-nothing approach. All children must confirm to query rule / query group structure, otherwise it gets chucked out.
// To test: 1/3 conditions fulfilled => returns true.
function validateChildren(children) {
  if (children.length === 0) return true;
  const corruptedConfigs = children.filter(
    (child) =>
      (!child.type || !validateQueryRule(child)) && !validateQueryGroup(child)
  );
  return corruptedConfigs.length === 0;
}

/**
 * Pseudocode of rules structure:
 * {
 *   query-builder-group: {
 *     ...query-builder-rule,
 *     ...query-builder-rule,
 *     query-builder-group: {
 *       ...query-builder-rule,
 *       query-builder-group: {
 *         ...query-builder-rule
 *       }
 *     },
 *   }
 * }
 * @param {rule Object} child
 * @returns boolean
 */
// Rules children must be an array of query-builder-rule or query-builder-group objects.
// validateQueryRule checks {
//     type: "query-builder-rule",
//     query: {
//       rule: "studyid",
//       selectedOperator: "contains",
//       selectedOperand: "Study ID",
//       value: "dsa",
//     },
//   }
function validateQueryRule(child) {
  // Null checks
  if (
    !child.type ||
    !child.query ||
    !child.query.rule ||
    !(child.query.selectedOperator || child.query.selectedOperand)
  )
    return false;

  // "Enum" value checks
  const { type, query } = child;
  const validRules = Object.keys(FIELD_NAMES);
  const validSelectedOperands = Object.values(FIELD_NAMES);
  if (
    type !== "query-builder-rule" ||
    (typeof query.value !== "string" && query.value !== null)
  )
    return false;
  if (!validRules.includes(query.rule)) return false;
  if (!validSelectedOperands.includes(query.selectedOperand)) return false;
  return true;
}

// Recursive child check.
// Checks {
//   "type": "query-builder-group",
//   "query": {
//     "logicalOperator": "all",
//     "children": [
//       {
//         "type": "query-builder-rule",
//         "query": {
//           "rule": "auth",
//           "selectedOperator": "equals", (optional)
//           "selectedOperand": "Author",
//           "value": null
//         }
//       }
//     ]
//   }
// }
function validateQueryGroup(child) {
  // Null checks
  if (
    !child.type ||
    !child.query ||
    !child.query.logicalOperator ||
    !child.query.children
  )
    return false;

  // "Enum" value checks
  const { type, query } = child;
  if (type !== "query-builder-group") return false;
  const corruptChildren = query.children.filter((child) => {
    !validateQueryRule(child) || !validateQueryGroup(child);
  });

  return corruptChildren.length === 0;
}
