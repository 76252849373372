import { Group, Tile } from 'ol/layer'
import { XYZ } from 'ol/source'

const APIKey = process.env.MAPTILER_API_KEY

export default new Group({
  title: 'Base maps',
  colour: "#cfd8dc",
  fold: 'close',
  layers: [
    new Tile({
        title: 'Maptiler Basic',
        type: 'base',
        visible: false,
        source: new XYZ({
          url: `https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}@2x.png?key=${APIKey}`,
          crossOrigin: 'anonymous',
          attributions: [
            '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
          ],
          tileSize: 512, tilePixelRatio: 2,
        }),
      }),
     new Tile({
        title: 'Maptiler Light',
        type: 'base',
        visible: false,
        source: new XYZ({
          url: `https://api.maptiler.com/maps/basic-v2-light/{z}/{x}/{y}@2x.png?key=${APIKey}`,
          crossOrigin: 'anonymous',
          attributions: [
            '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
          ],
          tileSize: 512, tilePixelRatio: 2,
        }),
      }),
     new Tile({
        title: 'Maptiler Dark',
        type: 'base',
        visible: false,
        source: new XYZ({
          url: `https://api.maptiler.com/maps/basic-v2-dark/{z}/{x}/{y}@2x.png?key=${APIKey}`,
          crossOrigin: 'anonymous',
          attributions: [
            '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
          ],
          tileSize: 512, tilePixelRatio: 2,
        }),
      }),
     new Tile({
        title: 'Maptiler Outdoor',
        type: 'base',
        visible: false,
        source: new XYZ({
          url: `https://api.maptiler.com/maps/outdoor-v2/{z}/{x}/{y}@2x.png?key=${APIKey}`,
          crossOrigin: 'anonymous',
          attributions: [
            '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
          ],
          tileSize: 512, tilePixelRatio: 2,
        }),
      }),
     new Tile({
        title: 'Maptiler Satellite',
        type: 'base',
        visible: false,
        source: new XYZ({
          url: `https://api.maptiler.com/maps/satellite/{z}/{x}/{y}@2x.jpg?key=${APIKey}`,
          crossOrigin: 'anonymous',
          attributions: [
          '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        ],
        tileSize: 512, tilePixelRatio: 2,
       }),
      }),
     new Tile({
        title: 'Maptiler Satellite (hybrid)',
        type: 'base',
        visible: false,
        source: new XYZ({
          url: `https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}@2x.jpg?key=${APIKey}`,
          crossOrigin: 'anonymous',
          attributions: [
          '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        ],
        tileSize: 512, tilePixelRatio: 2,
       }),
      }),
     /*
     new Tile({
      title: 'Open Street Map',
      type: 'base',
      visible: false,
      source: new XYZ({
        crossOrigin: 'anonymous',
        url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attributions: [
          '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        ],
        tileSize: 256,
      }),
    }), */
  ],
})
