<template>
  <!-- TODO: DRY this out? OK as-is for proof-of-concept -->
  <ul :class="['layer-switcher', {radio}]">
    <li v-for="group in groups">
      <span v-if="!radio" class="layer-title toggle group">
        <input type="checkbox"
               :checked="group.state_ && group.state_.visible"
               @click="(e) => group.setVisible(e.target.checked)" />
        {{ group.getProperties().title }}
      </span>
      <ul v-if="group.getLayers" :style="{ borderLeftColor: group.values_.colour }">
        <li v-for="layer in group.getLayers().getArray()"
            :class="{disabled: (!group.state_ || !group.state_.visible)}">
          <span class="layer-title toggle layer">
            <!-- this logic means radio is tied to map tile selection and checkbox to data layers. Can't repurpose. -->
            <input :type="radio ? 'radio': 'checkbox'"
                   :name="radio ? `group-${group.ol_uid}` : `layer-${layer.ol_uid}`"
                   :checked="group.state_ && group.state_.visible && layer.state_ && layer.state_.visible"
                   :disabled="!group.state_ || !group.state_.visible"
                   @click="(e) => radio ? selectBaseLayer(layer) : layer.setVisible(e.target.checked)"
                   />
            {{ layer.getProperties().title }}
          </span>
          <ul v-if="layer.getLayers && !radio">
            <li v-for="sublayer in layer.getLayers().getArray()" :class="{disabled: (!group.state_ || !group.state_.visible || !layer.state_ || !layer.state_.visible)}">
              <span class="layer-title toggle layer">
                <input type="checkbox"
                       :checked="group.state_ && group.state_.visible && layer.state_ && layer.state_.visible && sublayer.state_ && sublayer.state_.visible"
                       :disabled="!group.state_ || !group.state_.visible || !layer.state_ || !layer.state_.visible"
                       @click="(e) => sublayer.setVisible(e.target.checked)"
                       />
                {{ sublayer.getProperties().title }}
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
  import Vue from 'vue'
  export default {
    props: {
      groups: Array,
      radio: Boolean,
    },
    data(){
      return {
        baseLayer: null,
      }
    },
    mounted() {
      const defaultBaseMap = this.groups[0].getLayers().getArray()[0]
      this.selectBaseLayer(defaultBaseMap)
    },
    methods: {
      selectBaseLayer(layer) {
        if (this.baseLayer) {
          this.baseLayer.setVisible(false)
        }
        layer.setVisible(true)
          Vue.set(this, 'baseLayer', layer)
        },
      },
  }
</script>

<style lang="scss">
.layer-switcher {
  &,
  ul {
    list-style: none;
    padding-left: 0;
    li {
      white-space: nowrap;
      line-height: 1.35;
      
      &.disabled {
        opacity: 0.5;
      }
    }
  }
  > li > .layer-title {
    background-color: #cfd8dc;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: block;
    margin-left: -15px;
    padding-left: 10px;
    margin-right: -15px;
    padding-right: 20px;
    font-weight: normal;
    margin-top: 20px;
  }
  > li > ul {
    padding-left: 10px;
    border-left: 3px solid #ccc;
    margin-left: 3px;
    margin-top: 5px;
  }
  > li > ul > li > ul {
    padding-left: 20px;
    margin: 3px 0;
  }
  &.radio {
    padding-left: 0;
  }
}
</style>
