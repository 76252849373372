import { el } from "redom";

export const INTENDED_USES = [
  {
    value: "Research (publication / presentation)",
    text: "Research (publication / presentation)",
  },
  {
    value: "Research (new project scoping / grant proposal)",
    text: "Research (new project scoping / grant proposal)",
  },
  {
    value: "Research (postgraduate student dissertation)",
    text: "Research (postgraduate student dissertation)",
  },
  {
    value: "Research (fieldwork preparation)",
    text: "Research (fieldwork preparation)",
  },
  { value: "Research (other)", text: "Research (other)" },
  {
    value: "Teaching (classroom teaching)",
    text: "Teaching (classroom teaching)",
  },
  {
    value: "Teaching (undergraduate project)",
    text: "Teaching (undergraduate project)",
  },
  { value: "Teaching (other)", text: "Teaching (other)" },
  { value: "Other intended use", text: "Other intended use" },
];

export const FIELD_NAMES = {
  // CRNDenudation
  obsid1: "OCTOPUS ID",
  obsid2: "Sample ID",
  studyid: "Study ID",
  author: "Author",
  pubyear: "Year",
  refid: "Reference ID",
  refdoi: "DOI",
  ahgfl1: "AHGF Level 1 river code",
  cntry: "Country code (ISO3)",
  material: "Type of material sampled",
  bestnd: "Be10 standardisation",
  beams: "Be10 AMS laboratory",
  alstnd: "Al26 standardisation",
  alams: "Al26 AMS laboratory",
  ebe_mmkyr: "Be10 E (mm/kyr)",
  ebe_err: "Error",
  eal_mmkyr: "Al26 E (mm/kyr)",
  eal_err: "Al26 error",
  area: "Basin area (km2)",
  elev_ave: "Mean basin elevation (m)",
  slp_ave: "Mean basin gradient (m/km)",
  
  // SahulSed OSL
  methodabbr: "Method",
  author1: "Author",
  pubyear1: "Year",
  refid1: "Reference ID",
  refdoi1: "DOI",
  osl_age: "Age (ka)",
  osl_err: "Error",
  osl_rnderr: "Age error - random errors only",
  region_reg: "Geographic region",
  div_admin: "Administrative region",
  div_other: "Sub-region of PNG and TSI",
  isl_name: "Name of island",
  ibracode: "IBRA7 bio-region",
  geotype: "Landform dated",
  facies: "Sedimentological facies type",
  sitetype: "Type of site sampled",
  smpdepth: "Sampling depth (m)",
  sed_mat: "Type of stratigraphic unit",
  col_mtd: "Sample collection method",
  lum_mat: "Type of material used",
  mineral: "Type of mineral used",
  osl_typeab: "Type of OSL method used",
  aliq_type: "Aliquot type used",
  depcon: "Deposition context",
  dunefield: "Name of dune field",
  geommod: "Geomorphic modifier",
  morph: "Morphology of feature",
  laketype: "Type of lake",
  lake: "Name of lake",
  
  // SahulSed TL
  tl_age: "Age (ka)",
  tl_err: "Error",
  tl_rnderr: "Age error - random errors only",
  
  // SahulArch OSL & TL
  sitecode: "Type of site sampled",
  openclosed: "Site: open/closed",
  featdated: "Specific feature dated",
  occupation: "Site related to human activity",
  context: "Human occupation",
  
  // SahulArch Radiocarbon
  c14_age: "Age (BP)",
  c14_errpos: "Error (+)",
  c14_errneg: "Error (-)",
  material1: "Type of material used",
  
  // FosSahul
  species: "Species",
  genus: "Genus name",
  megafauna: "Megafauna",
  age: "Age (ka)",
  age_err: "Precision",
  age_type: "Type",
  age_q: "Quality",
  age_assoc1: "Age association: direct/indirect",
  fosmat1: "Type of remain dated",
  fosmat2: "Type of material dated",
  
  //ExpAge
  region_int: "Geographic region",
  elevation: "Elevation (m)",
  thickness: "Sample thickness (cm)",
  density: "Density of material (g/cm3)",
  shielding: "Shielding factor",
  abe_yr: "Be10 age (kyr)",
  abe_errext: "Error ext.",
  abe_errint: "Error int.",
  aal_yr: "Al26 age (kyr)",
  aal_errext: "Al26 ext. error",
  aal_errint: "Al26 int. error",

  //SahulChar
  unitid: "Unit ID",
  unithandle: "Code",
  unittype: "Archive Type",
  deposit: "Depositional unit",
  maxage: "Max age (BP)",
  n_char_rec: "Number records",
  biome: "Biome",
  catchsize: "Catchment size",
  basinsize: "Basin (lake) size",
  flowtype: "Flow type",

  //IPPD
  datasetid: "Dataset ID",
  datsettype: "Dataset Type",
  datasetdoi: "Neotoma DOI",
  ds_maxage: "Max age (BP)",
  ds_minage: "Min age (BP)",
  unitname: "Unit name",
  sitename: "Site name"
  
};

export function formatFieldName(field) {
  if (Object.keys(FIELD_NAMES).indexOf(field) >= 0) {
    return FIELD_NAMES[field];
  }
  return field;
}

const CRN_BASINS_FIELDS = ["obsid1", "obsid2", "studyid", "author", "pubyear", "refdoi", "ebe_mmkyr", "ebe_err"];
const CRN_OUTLETS_FIELDS = ["obsid1", "obsid2"];
const CRN_QUERY_FIELDS = ["studyid", "author", "pubyear", "ahgfl1", "cntry", "material", "bestnd", "beams", "alstnd", "alams", "ebe_mmkyr", "eal_mmkyr", "area", "elev_ave", "slp_ave"];

// SahulSed and SahulArch Fields
const SAHUL_COMMON_FIELDS = ["obsid1", "obsid2", "methodabbr", "author1", "pubyear1", "refdoi1"];
const SAHUL_OSL_FIELDS = [].concat(SAHUL_COMMON_FIELDS, ["osl_age", "osl_err"]);
const SAHUL_TL_FIELDS = [].concat(SAHUL_COMMON_FIELDS, ["tl_age", "tl_err"]);
const SAHULARCH_RADIOCARBON_FIELDS = [].concat(SAHUL_COMMON_FIELDS, ["c14_age", "c14_errpos", "c14_errneg"]);


//SahulSed Query Fields
const SAHULSED_COMMON_QUERY_FIELDS = ["author1", "pubyear1", "cntry", "div_admin", "ibracode", "geotype", "sed_mat", "col_mtd", "lum_mat", "mineral"];
const SAHULSED_COMMON_OSL_QUERY_FIELDS = [].concat(SAHULSED_COMMON_QUERY_FIELDS, ["osl_typeab", "aliq_type", "osl_age"]);
const SAHULSED_COMMON_TL_QUERY_FIELDS = [].concat(SAHULSED_COMMON_QUERY_FIELDS, ["tl_age"]);
const SAHULSED_FLV_OSL_QUERY_FIELDS = [].concat(SAHULSED_COMMON_OSL_QUERY_FIELDS, ["sitetype", "smpdepth"]);
const SAHULSED_FLV_TL_QUERY_FIELDS = [].concat(SAHULSED_COMMON_TL_QUERY_FIELDS, ["sitetype", "smpdepth"]);
const SAHULSED_AEN_OSL_QUERY_FIELDS = [].concat(SAHULSED_COMMON_OSL_QUERY_FIELDS, ["sitetype", "smpdepth", "depcon", "dunefield", "geommod", "morph"]);
const SAHULSED_AEN_TL_QUERY_FIELDS = [].concat(SAHULSED_COMMON_TL_QUERY_FIELDS, ["sitetype", "smpdepth", "depcon", "dunefield", "geommod", "morph"]);
const SAHULSED_LAC_OSL_QUERY_FIELDS = [].concat(SAHULSED_COMMON_OSL_QUERY_FIELDS, ["facies", "laketype", "lake"]);
const SAHULSED_LAC_TL_QUERY_FIELDS = [].concat(SAHULSED_COMMON_TL_QUERY_FIELDS, ["facies", "laketype", "lake"]);
const SAHULSED_CST_OSL_QUERY_FIELDS = [].concat(SAHULSED_COMMON_OSL_QUERY_FIELDS, ["facies", "sitetype", "smpdepth"]);
const SAHULSED_CST_TL_QUERY_FIELDS = [].concat(SAHULSED_COMMON_OSL_QUERY_FIELDS, ["facies", "sitetype", "smpdepth"]);

//SahulArch Query Fields
const SAHULARCH_COMMON_QUERY_FIELDS = ["author1", "pubyear1", "cntry", "region_reg", "div_admin", "div_other", "isl_name", "ibracode", "sitecode", "openclosed", "featdated", "occupation", "context", "col_mtd"];
const SAHULARCH_OSL_QUERY_FIELDS = [].concat(SAHULARCH_COMMON_QUERY_FIELDS, ["lum_mat", "mineral", "osl_typeab", "aliq_type", "osl_age"]);
const SAHULARCH_TL_QUERY_FIELDS = [].concat(SAHULARCH_COMMON_QUERY_FIELDS, ["lum_mat", "mineral", "tl_age"]);
const SAHULARCH_RADIOCARBON_QUERY_FIELDS = [].concat(SAHULARCH_COMMON_QUERY_FIELDS, ["material1", "c14_age"]);

//FosSahul Fields
const FOSSAHUL_FIELDS = ["obsid1", "obsid2", "methodabbr", "author1", "pubyear1", "refdoi1", "species", "genus", "megafauna", "age", "age_err", "age_type", "age_q"];
const FOSSAHUL_QUERY_FIELDS = ["methodabbr", "author1", "pubyear1", "cntry", "region_reg", "div_admin", "div_other", "isl_name", "lake", "ibracode", "fosmat1", "fosmat2", "species", "genus", "megafauna", "age", "age_type", "age_q", "age_assoc1"];

//ExpAge Fields
const EXPAGE_FIELDS = ["obsid1", "obsid2", "author1", "pubyear1", "refdoi1", "abe_yr", "abe_errext", "abe_errint"];
const EXPAGE_QUERY_FIELDS = ["author1", "pubyear1", "cntry", "elevation", "thickness", "density", "shielding", "abe_yr", "aal_yr"];

//SahulChar Fields
const SAHULCHAR_FIELDS = ["unitid", "unithandle", "unittype", "author", "pubyear", "refdoi", "maxage","n_char_rec"];
const SAHULCHAR_QUERY_FIELDS = ["author","pubyear","cntry","region_reg", "div_admin", "isl_name", "lake", "ibracode", "unithandle", "unittype", "deposit", "maxage", "n_char_rec", "sitecode", "biome", "catchsize", "basinsize", "flowtype"];

//IPPD Fields
const IPPD_FIELDS = ["unithandle", "unittype", "deposit", "datsettype", "datasetdoi", "ds_maxage", "ds_minage", "author", "pubyear", "refdoi"];
const IPPD_QUERY_FIELDS = ["datasetid", "datsettype", "ds_maxage", "ds_minage", "unitname", "unithandle", "unittype", "deposit", "sitename", "biome", "catchsize", "basinsize", "flowtype"];

var verbatim = function verbatim(values) {
  return values.map(function (value) {
    return {
      value: value,
      label: value
    };
  });
};

export const QUERY_CONFIG = {
  studyid: {
    type: "text"
  },
  author: {
    type: "text"
  },
  pubyear: {
    type: "numeric"
  },
  ahgfl1: {
    type: "select",
    choices: [{
      value: "CC",
      label: "Carpentaria Coast"
    }, {
      value: "LEB",
      label: "Lake Eyre Basin"
    }, {
      value: "MDB",
      label: "Murray-Darling Basin"
    }, {
      value: "NEC",
      label: "North East Coast"
    }, {
      value: "NWP",
      label: "North Western Plateau"
    }, {
      value: "PG",
      label: "Pilbara-Gascoyne"
    }, {
      value: "SAG",
      label: "South Australian Gulf"
    }, {
      value: "SEN",
      label: "South East Coast NSW"
    }, {
      value: "SEV",
      label: "South East Coast VIC"
    }, {
      value: "SWC",
      label: "South West Coast"
    }, {
      value: "SWP",
      label: "South Western Plateau"
    }, {
      value: "TAS",
      label: "Tasmania"
    }, {
      value: "TTS",
      label: "Tanami-Timor Sea Coast"
    }, {
      value: "NA",
      label: "[outside Australia]"
    }]
  },
  cntry: {
    type: "text"
  },
  material: {
    type: "select",
    choices: [{
      value: "Sa",
      label: "Sand"
    }, {
      value: "Gr",
      label: "Gravel"
    }, {
      value: "SaGr",
      label: "Mix of sand and gravel"
    }, {
      value: "Sed",
      label: "Sediment (unspecified)"
    }]
  },
  bestnd: {
    type: "select",
    choices: verbatim(["07KNSTD", "KNSTD", "BEST433", "BEST433N", "LLNL1000", "LLNL10000", "LLNL300", "LLNL3000", "LLNL31000", "NIST_30000", "NIST_30200", "NIST_30300", "NIST_30500", "NIST_30600", "NIST_Certified", "S2007", "S2007N", "S555", "S555N"])
  },
  beams: {
    type: "select",
    choices: verbatim(["ANSTO", "ANU", "ASTER", "Cologne", "DREAMS", "ETH-Zurich", "Gif-sur-Yvette", "iThemba LABS", "KIGAM AMS", "KIST Seoul", "LLNL-CAMS", "MALT Tokyo AMS", "PRIME-Lab", "SUERC", "Uppsala", "XAAMS", "XCAMS (GNS)", "Tianjin"])
  },
  alstnd: {
    type: "select",
    choices: verbatim(["KNSTD", "SMAL11", "ZAL94", "ZAL94N"])
  },
  alams: {
    type: "select",
    choices: verbatim(["ANSTO", "ANU", "ASTER", "Cologne", "DREAMS", "ETH-Zurich", "Gif-sur-Yvette", "iThemba LABS", "KIGAM AMS", "KIST Seoul", "LLNL-CAMS", "MALT Tokyo AMS", "PRIME-Lab", "SUERC", "Uppsala", "XAAMS", "XCAMS (GNS)", "Tianjin"])
  },
  ebe_mmkyr: {
    type: "numeric"
  },
  eal_mmkyr: {
    type: "numeric"
  },
  area: {
    type: "numeric"
  },
  elev_ave: {
    type: "numeric"
  },
  slp_ave: {
    type: "numeric"
  },
  region_reg: {
    type: "select",
    choices: [{
      value: "AUS",
      label: "Australia (without TSI)"
    }, {
      value: "IDN",
      label: "Indonesia"
    }, {
      value: "PNG",
      label: "Papua New Guinea (without TSI)"
    }, {
      value: "TSI",
      label: "Torres Strait Islands"
    }, {
      value: "NZL",
      label: "New Zealand"
    }]
  },
  div_admin: {
    type: "select",
    choices: [{
      value: "AU-ACT",
      label: "Australian Capital Territory"
    }, {
      value: "AU-NSW",
      label: "New South Wales"
    }, {
      value: "AU-NT",
      label: "Northern Territory"
    }, {
      value: "AU-QLD",
      label: "Queensland"
    }, {
      value: "AU-SA",
      label: "South Australia"
    }, {
      value: "AU-TAS",
      label: "Tasmania"
    }, {
      value: "AU-VIC",
      label: "Victoria"
    }, {
      value: "AU-WA",
      label: "Western Australia"
    }, {
      value: "ID-JW",
      label: "Jawa"
    }, {
      value: "ID-KA",
      label: "Kalimantan"
    }, {
      value: "ID-ML",
      label: "Maluku"
    }, {
      value: "ID-NU",
      label: "Nusa Tenggara"
    }, {
      value: "ID-PP",
      label: "Papua"
    }, {
      value: "ID-SL",
      label: "Sulawesi"
    }, {
      value: "ID-SM",
      label: "Sumatera"
    }, {
      value: "PG-NCD",
      label: "National Capital District"
    }, {
      value: "PG-CPM",
      label: "Central"
    }, {
      value: "PG-CPK",
      label: "Chimbu"
    }, {
      value: "PG-EHG",
      label: "Eastern Highlands"
    }, {
      value: "PG-EBR",
      label: "East New Britain"
    }, {
      value: "PG-ESW",
      label: "East Sepik"
    }, {
      value: "PG-EPW",
      label: "Enga"
    }, {
      value: "PG-GPK",
      label: "Gulf"
    }, {
      value: "PG-HLA",
      label: "Hela"
    }, {
      value: "PG-JWK",
      label: "Jiwaka"
    }, {
      value: "PG-MPM",
      label: "Madang"
    }, {
      value: "PG-MRL",
      label: "Manus"
    }, {
      value: "PG-MBA",
      label: "Milne Bay"
    }, {
      value: "PG-MPL",
      label: "Morobe"
    }, {
      value: "PG-NIK",
      label: "New Ireland"
    }, {
      value: "PG-NPP",
      label: "Northern"
    }, {
      value: "PG-SAN",
      label: "West Sepik"
    }, {
      value: "PG-SHM",
      label: "Southern Highlands"
    }, {
      value: "PG-WPD",
      label: "Western"
    }, {
      value: "PG-WHM",
      label: "Western Highlands"
    }, {
      value: "PG-WBK",
      label: "West New Britain"
    }, {
      value: "PG-NSB",
      label: "Bougainville"
    }]
  },
  div_other: {
    type: "select",
    choices: [{
      value: "PG-Highlands",
      label: "Highlands Region"
    }, {
      value: "PG-Islands",
      label: "Islands Region"
    }, {
      value: "PG-Momase",
      label: "Momase Region"
    }, {
      value: "PG-Papua",
      label: "Papua Region"
    }, {
      value: "TSI-TWI",
      label: "Top Western Islands"
    }, {
      value: "TSI-NWI",
      label: "Near Western Islands"
    }, {
      value: "TSI-INI",
      label: "Inner Islands"
    }, {
      value: "TSI-CI",
      label: "Central Islands"
    }, {
      value: "TSI-EI",
      label: "Eastern Islands"
    }]
  },
  isl_name: {
    type: "text"
  },
  ibracode: {
    type: "select",
    choices: [{
      value: "ARC",
      label: "Arnhem Coast"
    }, {
      value: "ARP",
      label: "Arnhem Plateau"
    }, {
      value: "AUA",
      label: "Australian Alps"
    }, {
      value: "AVW",
      label: "Avon Wheatbelt"
    }, {
      value: "BBN",
      label: "Brigalow Belt North"
    }, {
      value: "BBS",
      label: "Brigalow Belt South"
    }, {
      value: "BEL",
      label: "Ben Lomond"
    }, {
      value: "BHC",
      label: "Broken Hill Complex"
    }, {
      value: "BRT",
      label: "Burt Plain"
    }, {
      value: "CAR",
      label: "Carnarvon"
    }, {
      value: "CEA",
      label: "Central Arnhem"
    }, {
      value: "CEK",
      label: "Central Kimberley"
    }, {
      value: "CER",
      label: "Central Ranges"
    }, {
      value: "CHC",
      label: "Channel Country"
    }, {
      value: "CMC",
      label: "Central Mackay Coast"
    }, {
      value: "COO",
      label: "Coolgardie"
    }, {
      value: "COP",
      label: "Cobar Peneplain"
    }, {
      value: "COS",
      label: "Coral Sea"
    }, {
      value: "CYP",
      label: "Cape York Peninsula"
    }, {
      value: "DAB",
      label: "Daly Basin"
    }, {
      value: "DAC",
      label: "Darwin Coastal"
    }, {
      value: "DAL",
      label: "Dampierland"
    }, {
      value: "DEU",
      label: "Desert Uplands"
    }, {
      value: "DMR",
      label: "Davenport Murchison Ranges"
    }, {
      value: "DRP",
      label: "Darling Riverine Plains"
    }, {
      value: "EIU",
      label: "Einasleigh Uplands"
    }, {
      value: "ESP",
      label: "Esperance Plains"
    }, {
      value: "EYB",
      label: "Eyre Yorke Block"
    }, {
      value: "FIN",
      label: "Finke"
    }, {
      value: "FLB",
      label: "Flinders Lofty Block"
    }, {
      value: "FUR",
      label: "Furneaux"
    }, {
      value: "GAS",
      label: "Gascoyne"
    }, {
      value: "GAW",
      label: "Gawler"
    }, {
      value: "GES",
      label: "Geraldton Sandplains"
    }, {
      value: "GFU",
      label: "Gulf Fall and Uplands"
    }, {
      value: "GID",
      label: "Gibson Desert"
    }, {
      value: "GSD",
      label: "Great Sandy Desert"
    }, {
      value: "GUC",
      label: "Gulf Coastal"
    }, {
      value: "GUP",
      label: "Gulf Plains"
    }, {
      value: "GVD",
      label: "Great Victoria Desert"
    }, {
      value: "HAM",
      label: "Hampton"
    }, {
      value: "ITI",
      label: "Indian Tropical Islands"
    }, {
      value: "JAF",
      label: "Jarrah Forest"
    }, {
      value: "KAN",
      label: "Kanmantoo"
    }, {
      value: "KIN",
      label: "King"
    }, {
      value: "LSD",
      label: "Little Sandy Desert"
    }, {
      value: "MAC",
      label: "MacDonnell Ranges"
    }, {
      value: "MAL",
      label: "Mallee"
    }, {
      value: "MDD",
      label: "Murray Darling Depression"
    }, {
      value: "MGD",
      label: "Mitchell Grass Downs"
    }, {
      value: "MII",
      label: "Mount Isa Inlier"
    }, {
      value: "MUL",
      label: "Mulga Lands"
    }, {
      value: "MUR",
      label: "Murchison"
    }, {
      value: "NAN",
      label: "Nandewar"
    }, {
      value: "NCP",
      label: "Naracoorte Coastal Plain"
    }, {
      value: "NET",
      label: "New England Tablelands"
    }, {
      value: "NNC",
      label: "NSW North Coast"
    }, {
      value: "NOK",
      label: "Northern Kimberley"
    }, {
      value: "NSS",
      label: "NSW South Western Slopes"
    }, {
      value: "NUL",
      label: "Nullarbor"
    }, {
      value: "OVP",
      label: "Ord Victoria Plain"
    }, {
      value: "PCK",
      label: "Pine Creek"
    }, {
      value: "PIL",
      label: "Pilbara"
    }, {
      value: "PSI",
      label: "Pacific Subtropical Islands"
    }, {
      value: "RIV",
      label: "Riverina"
    }, {
      value: "SAI",
      label: "Subantarctic Islands"
    }, {
      value: "SCP",
      label: "South East Coastal Plain"
    }, {
      value: "SEC",
      label: "South East Corner"
    }, {
      value: "SEH",
      label: "South Eastern Highlands"
    }, {
      value: "SEQ",
      label: "South Eastern Queensland"
    }, {
      value: "SSD",
      label: "Simpson Strzelecki Dunefields"
    }, {
      value: "STP",
      label: "Stony Plains"
    }, {
      value: "STU",
      label: "Sturt Plateau"
    }, {
      value: "SVP",
      label: "Southern Volcanic Plain"
    }, {
      value: "SWA",
      label: "Swan Coastal Plain"
    }, {
      value: "SYB",
      label: "Sydney Basin"
    }, {
      value: "TAN",
      label: "Tanami"
    }, {
      value: "TCH",
      label: "Tasmanian Central Highlands"
    }, {
      value: "TIW",
      label: "Tiwi Cobourg"
    }, {
      value: "TNM",
      label: "Tasmanian Northern Midlands"
    }, {
      value: "TNS",
      label: "Tasmanian Northern Slopes"
    }, {
      value: "TSE",
      label: "Tasmanian South East"
    }, {
      value: "TSR",
      label: "Tasmanian Southern Ranges"
    }, {
      value: "TWE",
      label: "Tasmanian West"
    }, {
      value: "VIB",
      label: "Victoria Bonaparte"
    }, {
      value: "VIM",
      label: "Victorian Midlands"
    }, {
      value: "WAR",
      label: "Warren"
    }, {
      value: "WET",
      label: "Wet Tropics"
    }, {
      value: "YAL",
      label: "Yalgoo"
    }]
  },
  geotype: {
    type: "select",
    choices: [{
      value: "Terrace",
      label: "Terrace"
    }, {
      value: "Floodplain",
      label: "Floodplain"
    }, {
      value: "Alluvial fan",
      label: "Alluvial fan"
    }, {
      value: "Bench",
      label: "Bench"
    }, {
      value: "Island",
      label: "Island"
    }, {
      value: "Slack water deposit",
      label: "Slack water deposit"
    }, {
      value: "Levee",
      label: "Levee"
    }, {
      value: "Dune",
      label: "Dune"
    }, {
      value: "Sandsheet",
      label: "Sandsheet"
    }, {
      value: "Beach / Shoreline",
      label: "Beach / Shoreline"
    }, {
      value: "Nearshore deposit",
      label: "Nearshore deposit"
    }, {
      value: "Lake floor",
      label: "Lake floor"
    }, {
      value: "Lakeshore terrace",
      label: "Lakeshore terrace"
    }, {
      value: "Deflated cliff",
      label: "Deflated cliff"
    }, {
      value: "Bar",
      label: "Bar"   
    }, {
      value: "Washover fan",
      label: "Washover fan"
    }, {
      value: "Aeolianite",
      label: "Aeolianite"
    }, {
      value: "Tsunami deposit",
      label: "Tsunami deposit"
    }, {
      value: "Marine terrace",
      label: "Marine terrace"
    }, {
      value: "Clifftop dune",
      label: "Clifftop dune"
    }, {
      value: "Coastal barrier (not specific)",
      label: "Coastal barrier (not specific)"
    }, {
      value: "Coastal barrier (ridge plain)",
      label: "Coastal barrier (ridge plain)"
    }, {
      value: "Coastal barrier (transgressive dune)",
      label: "Coastal barrier (transgressive dune)"
    }, {
      value: "Cheniers",
      label: "Cheniers"
    }, {
      value: "Flood-tide delta",
      label: "Flood-tide delta"
    }, {
      value: "Terrestrial dune",
      label: "Terrestrial dune"
    }, {
      value: "Estuarine floodplain",
      label: "Estuarine floodplain"
    }, {
      value: "Delta front",
      label: "Delta front"
    }, {
      value: "Shoreface",
      label: "Shoreface"
    }, {
      value: "Deep sea bed",
      label: "Deep sea bed"    
    }]
  },
  facies: {
    type: "select",
    choices: [{
      value: "Channel",
      label: "Channel"
    }, {
      value: "Overbank",
      label: "Overbank"
    }, {
      value: "Beach / Shoreline",
      label: "Beach / Shoreline"
    }, {
      value: "Deep-water lacustrine",
      label: "Deep-water lacustrine"
    }, {
      value: "Shallow-water lacustrine",
      label: "Shallow-water lacustrine"
    }, {
      value: "Near-shore lacustrine",
      label: "Near-shore lacustrine"
    }, {
      value: "Lacustrine (water depth unclear)",
      label: "Lacustrine (water depth unclear)"
    }, {
      value: "Playa",
      label: "Playa"
    }, {
      value: "Calcareous deposits",
      label: "Calcareous deposits"
	}, {
      value: "Dune",
      label: "Dune"
    }, {
      value: "Washover",
      label: "Washover"
    }, {
      value: "Cyclone or storm deposit",
      label: "Cyclone or storm deposit"
    }, {
      value: "Back-barrier / estuarine",
      label: "Back-barrier / estuarine"
    }, {
      value: "Lagoonal / lacustrine",
      label: "Lagoonal / lacustrine"
    }, {
      value: "Tidal flat",
      label: "Tidal flat"
    }, {
      value: "Nearshore (to wave base)",
      label: "Nearshore (to wave base)"
    }, {
      value: "Inner shelf",
      label: "Inner shelf"
    }, {
      value: "Outer shelf",
      label: "Outer shelf"
    }, {
      value: "Sea Floor Sediments",
      label: "Sea Floor Sediments"
    }, {
      value: "Estuarine clay / mud",
      label: "Estuarine clay / mud"
    }, {
      value: "Channel sands and gravels",
      label: "Channel sands and gravels"
    }, {
      value: "Estuarine sand",
      label: "Estuarine sand"      
    }]
  },
  sitetype: {
    type: "select",
    choices: verbatim(["Outcrop", "Core", "Auger hole", "Pit or Quarry", "Artificial excavation (trench)"])
  },
  smpdepth: {
    type: "numeric"
  },
  sed_mat: {
    type: "select",
    choices: [{
      value: "Sa",
      label: "Sand"
    }, {
      value: "Si",
      label: "Silt"
    }, {
      value: "Cl",
      label: "Clay"
    }]
  },
  author1: {
    type: "text"
  },
  pubyear1: {
    type: "numeric"
  },
  col_mtd: {
    type: "select",
    choices: [{
      value: "Auger",
      label: "Auger"
    }, {
      value: "Block",
      label: "Sediment block"
    }, {
      value: "Core",
      label: "Core"
    }, {
      value: "Excav",
      label: "Excavated"
    }, {
      value: "Tube",
      label: "Tube"
    }, {
      value: "InSitu",
      label: "In Situ"
    }, {
      value: "Sieve",
      label: "Sieve"
    }, {
      value: "Other",
      label: "Other collection method"
    }]
  },
  lum_mat: {
    type: "select",
    choices: [{
      value: "Burnt stone",
      label: "Burnt stone"
    }, {
      value: "Cobbles",
      label: "Cobbles"
    }, {
      value: "Sediment",
      label: "Sediment"
    }, {
      value: "Rock surface",
      label: "Rock surface"
    }, {
      value: "Mud wasp nest",
      label: "Mud wasp nest"
    }, {
      value: "Other",
      label: "Other material"
    }]
  },
  mineral: {
    type: "select",
    choices: [{
      value: "Calcite/Aragonite",
      label: "Calcite / Aragonite"
    }, {
      value: "Potassium Feldspar",
      label: "Potassium Feldspar"
    }, {
      value: "Sodium Feldspar",
      label: "Sodium Feldspar"
    }, {
      value: "Polymineral",
      label: "Polymineral"
    }, {
      value: "Quartz",
      label: "Quartz"
    }]
  },
  osl_typeab: {
    type: "select",
    choices: verbatim(["IRSL", "MET-pIRIR", "OSL", "pIRIR", "TT-OSL", "VSL", "Other method"])
  },
  aliq_type: {
    type: "select",
    choices: [{
      value: "SG",
      label: "Single grain"
    }, {
      value: "SA",
      label: "Single aliquot"
    }, {
      value: "MA",
      label: "Multiple aliquots"
    }]
  },
  osl_age: {
    type: "numeric"
  },
  tl_age: {
    type: "numeric"
  },
  depcon: {
    type: "select",
    choices: [{
      value: "Isolated dune",
      label: "Isolated dune"
    }, {
      value: "Dunefield",
      label: "Dunefield"
    }, {
      value: "Aeolian cap on beach ridge",
      label: "Aeolian cap on beach ridge"
    }, {
      value: "Sandsheet",
      label: "Sandsheet"
    }, {
      value: "Coastal shoreline",
      label: "Coastal shoreline"
    }]
  },
  dunefield: {
    type: "text"
  },
  geommod: {
    type: "select",
    choices: [{
      value: "No modifier, i.e., 'normal' dune",
      label: "No modifier ('normal' dune)"
    }, {
      value: "Lacustrine source bordering",
      label: "Lacustrine source bordering"
    }, {
      value: "Lakefloor",
      label: "Lakefloor"
    }, {
      value: "Fluvial source bordering",
      label: "Fluvial source bordering"
    }, {
      value: "Obstacle",
      label: "Obstacle"
    }, {
      value: "Coastal",
      label: "Coastal"
    }, {
      value: "Blowout",
      label: "Blowout"
    }]
  },
  morph: {
    type: "select",
    choices: [{
      value: "Climbing",
      label: "Climbing"
    }, {
      value: "Falling",
      label: "Falling"
    }, {
      value: "Foredune",
      label: "Foredune"
    }, {
      value: "Longitudinal",
      label: "Longitudinal"
    }, {
      value: "Lunette",
      label: "Lunette"
    }, {
      value: "Nebkha",
      label: "Nebkha"
    }, {
      value: "Network",
      label: "Network"
    }, {
      value: "Parabolic",
      label: "Parabolic"
    }, {
      value: "Sand sheet",
      label: "Sand sheet"
    }, {
      value: "Transverse",
      label: "Transverse"
    }, {
      value: "Transverse shoreline",
      label: "Transverse shoreline"
    }]
  },
  laketype: {
    type: "select",
    choices: [{
      value: "Tectonic lake",
      label: "Tectonic lake"
    }, {
      value: "Landslide lake",
      label: "Landslide lake"
    }, {
      value: "Crater lake",
      label: "Crater lake"
    }, {
      value: "Oxbow lake",
      label: "Oxbow lake"
    }, {
      value: "Playa lake",
      label: "Playa lake"
    }, {
      value: "Fen",
      label: "Fen"
    }, {
      value: "Swamp",
      label: "Swamp"
    }, {
      value: "Lagoon",
      label: "Lagoon"
    }]
  },
  lake: {
    type: "text"
  },
  sitecode: {
    type: "select",
    choices: [{
      value: "Stone artefact deposit",
      label: "Stone artefact deposit"
    }, {
      value: "Burial (animal)",
      label: "Burial (animal)"
    }, {
      value: "Burial (human)",
      label: "Burial (human)"
    }, {
      value: "Cemetery",
      label: "Cemetery"
    }, {
      value: "Earth mound",
      label: "Earth mound"
    }, {
      value: "Hearth (isolated)",
      label: "Hearth (isolated)"
    }, {
      value: "Shell midden",
      label: "Shell midden"
    }, {
      value: "Open site",
      label: "Open site"
    }, {
      value: "Quarry",
      label: "Quarry"
    }, {
      value: "Rock art",
      label: "Rock art"
    }, {
      value: "Rockshelter or cave",
      label: "Rockshelter or cave"
    }, {
      value: "Culturally modified tree",
      label: "Culturally modified tree"
    }, {
      value: "Stone structure",
      label: "Stone structure"
    }, {
      value: "Alluvial",
      label: "Alluvial"
    }, {
      value: "Archaeological",
      label: "Archaeological"
    }, {
      value: "Bog",
      label: "Bog"
    }, {
      value: "Cave (indet.)",
      label: "Cave (indet.)"
    }, {
      value: "Coastal fen",
      label: "Coastal fen"
    }, {
      value: "Coastal origin",
      label: "Coastal origin"
    }, {
      value: "Estuarine",
      label: "Estuarine"
    }, {
      value: "Fen",
      label: "Fen"
    }, {
      value: "Fluvial origin",
      label: "Fluvial origin"
    }, {
      value: "Lacustrine (Drained lake)",
      label: "Lacustrine (Drained lake)"
    }, {
      value: "Lacustrine (Fluvial origin)",
      label: "Lacustrine (Fluvial origin)"
    }, {
      value: "Lacustrine (Glacial origin)",
      label: "Lacustrine (Glacial origin)"
    }, {
      value: "Lacustrine (Tectonic origin)",
      label: "Lacustrine (Tectonic origin)"
    }, {
      value: "Lacustrine origin",
      label: "Lacustrine origin"
    }, {
      value: "Marine origin",
      label: "Marine origin"
    }, {
      value: "Marsh",
      label: "Marsh"
    }, {
      value: "Mire",
      label: "Mire"
    }, {
      value: "Mound spring or spring",
      label: "Mound spring or spring"
    }, {
      value: "Playa",
      label: "Playa"
    }, {
      value: "Salt lake (permanent)",
      label: "Salt lake (permanent)"
    }, {
      value: "Salt marsh",
      label: "Salt marsh"
    }, {
      value: "Small hollow",
      label: "Small hollow"
    }, {
      value: "Terrestrial (indet.)",
      label: "Terrestrial (indet.)"
    }, {
      value: "Volcanic lake",
      label: "Volcanic lake"
    }, {
      value: "Other",
      label: "Other site type"
    }, {
      value: "no data",
      label: "No data"
    }]
  },
  openclosed: {
    type: "select",
    choices: verbatim(["Open", "Closed"])
  },
  featdated: {
    type: "select",
    choices: [{
      value: "Hearth",
      label: "Hearth"
    }, {
      value: "Burial (animal)",
      label: "Burial (animal)"
    }, {
      value: "Burial (human)",
      label: "Burial (human)"
    }, {
      value: "Mud wasp nest",
      label: "Mud wasp nest"
    }, {
      value: "Rock art",
      label: "Rock art"
    }, {
      value: "Other",
      label: "Other feature type"
    }]
  },
  occupation: {
    type: "select",
    choices: verbatim(["Yes", "No"])
  },
  context: {
    type: "select",
    choices: verbatim(["Occupation", "Sterile"])
  },
  material1: {
    type: "select",
    choices: [{
      value: "CarbBio",
      label: "Biogenic carbonate"
    }, {
      value: "CarbInorg",
      label: "Inorganic carbonate"
    }, {
      value: "Charred",
      label: "Charred plant"
    }, {
      value: "Faunal",
      label: "Faunal"
    }, {
      value: "Oxalate",
      label: "Oxalate"
    }, {
      value: "Paint",
      label: "Paint"
    }, {
      value: "Plant",
      label: "Plant"
    }, {
      value: "Sediment",
      label: "Sediment"
    }, {
      value: "Other",
      label: "Other material"
    }]
  },
  c14_age: {
    type: "numeric"
  },
  methodabbr: {
    type: "select",
    choices: [{
      value: "AAR",
      label: "Amino Acid Racemization"
    }, {
      value: "C14",
      label: "Radiocarbon dating"
    }, {
      value: "CRD",
      label: "Cation Ratio Dating"
    }, {
      value: "ESR",
      label: "Electron Spin Resonance"
    }, {
      value: "OCR",
      label: "Oxidisable Carbon Ration"
    }, {
      value: "OSL",
      label: "Optically Stimulated Luminescence"
    }, {
      value: "TL",
      label: "Thermoluminescence"
    }, {
      value: "U",
      label: "Uranium-Series"
    }, {
      value: "CSU-ESR",
      label: "Closed-system U-Series and ESR model"
    }, {
      value: "Strat",
      label: "Stratigraphic correlation"
    }, {
      value: "U-ESR",
      label: "Coupled U-ESR model"
    }]
  },
  fosmat1: {
    type: "select",
    choices: [{
      value: "Bone",
      label: "Bone"
    }, {
      value: "Bone_bulk",
      label: "Bulk bone"
    }, {
      value: "Bone_mult",
      label: "Multiple bones"
    }, {
      value: "Calc_bone",
      label: "Calcite filling in bone"
    }, {
      value: "Calc_straw",
      label: "Calcite straw (reworked in bone breccia)"
    }, {
      value: "Calc_tooth",
      label: "Calcite within tooth"
    }, {
      value: "Cement",
      label: "Calcite cement"
    }, {
      value: "Carcass",
      label: "Carcass remains"
    }, {
      value: "Charcoal",
      label: "Charcoal"
    }, {
      value: "Dipto_crop",
      label: "Crop content of Diprotodon"
    }, {
      value: "Nodule",
      label: "Carbonate nodule"
    }, {
      value: "Eggshell",
      label: "Eggshell"
    }, {
      value: "Fireplace",
      label: "Fireplace"
    }, {
      value: "Fish_olth",
      label: "Fish otolith"
    }, {
      value: "Flow",
      label: "Flowstone"
    }, {
      value: "Flow_old",
      label: "Flowstone (oldest)"
    }, {
      value: "Flow_young",
      label: "Flowstone (youngest)"
    }, {
      value: "Flow_straw",
      label: "Flowstone straw"
    }, {
      value: "Gut",
      label: "Gut content"
    }, {
      value: "Hair_sarco",
      label: "Sarcophilus hair"
    }, {
      value: "Midden",
      label: "Midden"
    }, {
      value: "Plant",
      label: "Plant remains"
    }, {
      value: "Post-deposit",
      label: "Post-depositional formation"
    }, {
      value: "Sed",
      label: "Sediment"
    }, {
      value: "Shell",
      label: "Shell"
    }, {
      value: "Shell_mare",
      label: "Marine shell"
    }, {
      value: "Skull",
      label: "Skull"
    }, {
      value: "Skull_dnty",
      label: "Skull (dentary)"
    }, {
      value: "Skull_max",
      label: "Skull (maxilla)"
    }, {
      value: "Snail",
      label: "Snail shell"
    }, {
      value: "Speleo",
      label: "Speleothem"
    }, {
      value: "Speleo_coral",
      label: "Coralline speleothem"
    }, {
      value: "Tooth",
      label: "Tooth"
    }, {
      value: "Wood",
      label: "Wood"
    }, {
      value: "Other",
      label: "Other remain"
    }]
  },
  fosmat2: {
    type: "select",
    choices: [{
      value: "Apatite",
      label: "Apatite"
    }, {
      value: "Bone",
      label: "Bone"
    }, {
      value: "Bone_mult",
      label: "Multiple bones"
    }, {
      value: "Bone_burnt",
      label: "Burnt bone"
    }, {
      value: "Calcite",
      label: "Calcite"
    }, {
      value: "Carb",
      label: "Carbonate"
    }, {
      value: "Charcoal",
      label: "Charcoal"
    }, {
      value: "Cellulose",
      label: "Cellulose"
    }, {
      value: "Collagen",
      label: "Collagen"
    }, {
      value: "Dentine",
      label: "Dentine"
    }, {
      value: "Dentine/Enamel",
      label: "Dentine / Enamel"
    }, {
      value: "Enamel",
      label: "Enamel"
    }, {
      value: "Eggshell",
      label: "Eggshell"
    }, {
      value: "Flesh",
      label: "Flesh"
    }, {
      value: "Gelatine_extr",
      label: "Gelatine extract"
    }, {
      value: "Gelatine_frac",
      label: "Gelatine fraction"
    }, {
      value: "Hair",
      label: "Hair"
    }, {
      value: "Insoble",
      label: "Acid and alkali insoluble residue"
    }, {
      value: "Insoble_acid_frac",
      label: "Acid insoluble fraction"
    }, {
      value: "Insoble_acid_res",
      label: "Acid insoluble residue"
    }, {
      value: "Molar",
      label: "Molar"
    }, {
      value: "Organic_layer",
      label: "Organic band"
    }, {
      value: "Organic_carb",
      label: "Organic carbon residue"
    }, {
      value: "Organic_frac",
      label: "Organic fraction"
    }, {
      value: "Organic_mat",
      label: "Organic material"
    }, {
      value: "Otolith",
      label: "Otolith"
    }, {
      value: "Peat",
      label: "Peat"
    }, {
      value: "Pellet_carb",
      label: "Carbonaceous pellet"
    }, {
      value: "Plant",
      label: "Leaf, seed, and stem fragments"
    }, {
      value: "Quartz",
      label: "Quartz"
    }, {
      value: "Seed_celtis",
      label: "Celtis seed"
    }, {
      value: "Shell",
      label: "Shell"
    }, {
      value: "Shell_veles",
      label: "Shell (Velesunio ambiguous)"
    }, {
      value: "Soil",
      label: "Bulk soil organics"
    }, {
      value: "Shell_mare",
      label: "Marine shell"
    }, {
      value: "Speleo",
      label: "Speleothem"
    }, {
      value: "Stalactite",
      label: "Straw stalactite"
    }, {
      value: "Tooth",
      label: "Tooth"
    }, {
      value: "Tooth_breccia",
      label: "Tooth from breccia"
    }, {
      value: "Wood",
      label: "Wood"
    }, {
      value: "Wood_burnt",
      label: "Burnt wood"
    }, {
      value: "Wood_carb",
      label: "Carbonised wood"
    }, {
      value: "Sed",
      label: "Sediment"
    }, {
      value: "Charcoal?",
      label: "Charcoal (assumed)"
    }, {
      value: "Other",
      label: "Other material"
    }]
  },
  species: {
    type: "text"
  },
  genus: {
    type: "text"
  },
  megafauna: {
    type: "select",
    choices: verbatim(["Yes", "No"])
  },
  age: {
    type: "numeric"
  },
  age_type: {
    type: "select",
    choices: [{
      value: "Min",
      label: "Minimum age"
    }, {
      value: "Max",
      label: "Maximum age"
    }, {
      value: "Exact",
      label: "Exact age"
    }]
  },
  age_q: {
    type: "select",
    choices: [{
      value: "A*",
      label: "Highly reliable"
    }, {
      value: "A",
      label: "Reliable"
    }, {
      value: "B",
      label: "Unreliable"
    }, {
      value: "C",
      label: "Highly unreliable"
    }]
  },
  age_assoc1: {
    type: "select",
    choices: verbatim(["Direct", "Indirect"])
  },
  elevation: {
    type: "numeric"
  },
  thickness: {
    type: "numeric"
  },
  density: {
    type: "numeric"
  },
  shielding: {
    type: "numeric"
  },
  abe_yr: {
    type: "numeric"
  },
  aal_yr: {
    type: "numeric"
  },
  unithandle: {
    type: "text"
  },
  unittype: {
    type: "select",
    choices: verbatim(["Animal midden", "Artefact", "Collection piece", "Composite", "Core", "Excavation", "Outcrop", "Plot", "Road cut", "Section", "Shell midden", "Surface float", "Trap", "Other"])
  },
  maxage: {
    type: "numeric"
  },
  n_char_rec: {
    type: "numeric"
  },
  catchsize: {
    type: "select",
    choices: [{
      value: "L",
      label: "Large (>500 sq.km)"
    }, {
      value: "M", 
      label: "Medium (10.1-500 sq.km)"
    }, {
      value: "S",
      label: "Small (10 sq.km)"
    }, {
      value: "ND",
      label: "No data"}],
  },
  basinsize: {
    type: "select",
    choices: [{
      value: "XL",
      label: "Very large (>500 sq.km)"
    }, {
      value: "L",
      label: "Large (50.1-500 sq.km)"
    }, {
      value: "M", 
      label: "Medium (1.1-50 sq.km)"
    }, {
      value: "S",
      label: "Small (0.01-1 sq.km)"
    }, {
      value: "XS",
      label: "Very small (<0.01 sq.km)"
    }, {
      value: "ND",
      label: "No data"}],
  },
  flowtype: {
    type: "select",
    choices: [{
      value: "inflow",
      label: "Inflow only"
    }, {
      value: "outflow",
      label: "Outflow only"
    }, {
      value: "open", 
      label: "Inflow & outflow"
    }, {
      value: "closed",
      label: "No inflow or outflow"
    }, {
      value: "unclear",
      label: "Unclear"
    }, {
      value: "ND",
      label: "No data"}],
  },
  biome: {
    type: "select",
    choices: [{
      value: "Barren",
      label: "Barren"
    }, {
      value: "Buttongrass moorland",
      label: "Buttongrass moorland"
    }, {
      value: "Cool grass- / shrubland",
      label: "Cool grass/shrubland"
    }, {
      value: "Cushion-forb tundra",
      label: "Cushion-forb tundra"
    }, {
      value: "Desert",
      label: "Desert"
    }, {
      value: "Desert and Xeric Shrublands",
      label: "Desert/Xeric Shrublands"
    }, {
      value: "Graminoid and forb tundra",
      label: "Graminoid/forb tundra"
    }, {
      value: "Grassland",
      label: "Grassland"
    }, {
      value: "Low and high shrub tundra",
      label: "Low/high shrub tundra"
    }, {
      value: "Marine",
      label: "Marine"
    }, {
      value: "Mediterranean",
      label: "Mediterranean"
      }, {
      value: "Mediterranean Forests, Woodlands, and Shrubs",
      label: "Mediterranean Forests/Woodlands/Shrubs"
      }, {
      value: "Montane Grasslands",
      label: "Montane Grasslands"
      }, {
      value: "Semidesert",
      label: "Semidesert"
      }, {
      value: "Subalpine (montane) woodland",
      label: "Subalpine woodland"
      }, {
      value: "Subtropical heathland",
      label: "Subtropical heathland"
      }, {
      value: "Temperate deciduous broadleaf forest",
      label: "Temp. deciduous broadleaf forest"
       }, {
      value: "Temperate evergreen needleleaf forest",
      label: "Temp. evergreen needleleaf forest"
       }, {
      value: "Temperate deciduous broadleaf forest",
      label: "Temp. deciduous broadleaf forest"
       }, {
      value: "Temperate forest",
      label: "Temperate forest"
       }, {
      value: "Temperate savanna",
      label: "Temperate savanna"
       }, {
      value: "Temperate sclerophyll woodland and shrubland",
      label: "Temp. sclerophyll woodland/shrubland"
       }, {
      value: "Temperate xerophytic shrubland",
      label: "Temp. xerophytic shrubland"
       }, {
      value: "Tidal",
      label: "Tidal"
       }, {
      value: "Tropical and Subtropical Dry Broadleaf Forests",
      label: "Trop./Subtrop. Dry Broadleaf Forests"
       }, {
      value: "Tropical and Subtropical Grasslands, Savannas, and Shrublands",
      label: "Trop./Subtrop. Grasslands/Savannas/Shrublands"
       }, {
      value: "Tropical and Subtropical Moist Broadleaf Forest",
      label: "Trop./Subtrop. Moist Broadleaf Forest"
       }, {
      value: "Tropical deciduous broadleaf forest and woodland",
      label: "Trop. deciduous broadleaf forest/woodland"
       }, {
      value: "Tropical dry forest",
      label: "Tropical dry forest"
       }, {
      value: "Tropical evergreen broadleaf",
      label: "Tropical evergreen broadleaf"
       }, {
      value: "Tropical rainforest",
      label: "Tropical rainforest"
       }, {
      value: "Tropical savanna",
      label: "Tropical savanna"
       }, {
      value: "Tropical semi-evergreen broadleaf",
      label: "Trop. semi-evergreen broadleaf"
       }, {
      value: "Tropical xerophytic shrubland",
      label: "Trop. xerophytic shrubland"
       }, {
      value: "Warm-temperate evergreen broadleaf and mixed",
      label: "Warm-temp. evergreen broadleaf/mixed"
    }, {
      value: "no data",
      label: "No biome data available"
    }] 
 },
  deposit: {
    type: "select",
    choices: verbatim(["Alluvial (non-specific)", "Archaeological (non-specific)", "Bog sediment", "Buried organic sediment", "Buried surface (non-specific)", "Estuarine sediment (non-specific)", "Fluvial origin (non-specific)", "Lake sediment", "Marine origin (non-specific)", "Other"])
  },
  datasetid: {
    type: "numeric"
  },
  ds_maxage: {
    type: "numeric"
  },
  ds_minage: {
    type: "numeric"
  },
  unitname: {
    type: "text"
  },
  sitename: {
    type: "text"
  },
  datsettype: {
    type: "select",
    choices: verbatim(["Charcoal", "Cladocera", "Diatom", "Geochemistry", "Geochronologic", "Insect", "Loss-on-ignition", "Macrocharcoal", "Microcharcoal", "Paleomagnetic", "Phytolith", "Plant macrofossil", "Pollen", "Pollen surface sample", "Specimen stable isotope", "X-ray fluorescence (XRF)"])
  }
};

export const getQueryRules = (fields) =>
  fields
    .filter((field) => Object.keys(QUERY_CONFIG).indexOf(field) >= 0)
    .map((field) => ({
      id: field,
      label: formatFieldName(field),
      ...QUERY_CONFIG[field],
    }));

function xDecimalPlaces(val, x) {
  try {
    return parseFloat(val).toFixed(x);
  } catch (e) {
    return val;
  }
}

const oneDecimalPlace = (val) => xDecimalPlaces(val, 1);
const twoDecimalPlaces = (val) => xDecimalPlaces(val, 2);

const formatYear = (val) => val.toString().split(".")[0];
const doiToUrl = (val) => {
  if (/^\d/.test(val)) {
    return `https://doi.org/${val}`;
  }
  if (/\/\//.test(val)) {
    return val;
  }
  return `http://${val}`;
};
const formatDOI = (val) =>
  el(
    "a",
    {
      href: doiToUrl(val),
      target: "_blank",
    },
    val
  );

const useChoices = (fieldName) => (val) =>
  QUERY_CONFIG[fieldName].choices
    .filter((choice) => choice.value === val)
    .map((choice) => choice.label)[0];

var FIELD_FORMATS = {
  pubyear: formatYear,
  pubyear1: formatYear,
  ebe_mmkyr: twoDecimalPlaces,
  ebe_err: twoDecimalPlaces,
  osl_age: oneDecimalPlace,
  osl_err: oneDecimalPlace,
  tl_age: oneDecimalPlace,
  tl_err: oneDecimalPlace,
  c14_age: oneDecimalPlace,
  c14_errpos: oneDecimalPlace,
  c14_errneg: oneDecimalPlace,
  age: oneDecimalPlace,
  age_err: oneDecimalPlace,
  abe_yr: oneDecimalPlace,
  abe_errext: oneDecimalPlace,
  abe_errint: oneDecimalPlace,
  aal_yr: oneDecimalPlace,
  aal_errext: oneDecimalPlace,
  aal_errint: oneDecimalPlace,
  maxage: oneDecimalPlace,
  ds_maxage: oneDecimalPlace,
  ds_minage: oneDecimalPlace,
  n_char_rec: formatYear,
  material: useChoices("material"),
  sed_matid: useChoices("sed_matid"),
  featdated: useChoices("featdated"),
  refdoi: formatDOI,
  refdoi1: formatDOI,
  datasetdoi: formatDOI
};

export function formatFieldValue(field, value) {
  if (Object.keys(FIELD_FORMATS).indexOf(field) >= 0) {
    return FIELD_FORMATS[field](value);
  }
  return value;
}

export const LAYER_HIERARCHY = [{
  title: "CRN Denudation",
  colour: "rgba(69, 90, 100, 0.5)",
  layers: [{
    title: "Global collection",
    layers: [{
      title: "River basins",
      layers: "be10-denude:crn_int_basins",
      polygonal: true,
      fields: CRN_BASINS_FIELDS,
      query_fields: CRN_QUERY_FIELDS,
      uppercase_field_names: true
    }, {
      title: "Sample sites",
      layers: "be10-denude:crn_int_outlets",
      fields: CRN_OUTLETS_FIELDS,
      cluster: true,
      colour: "rgba(74, 20, 134, 0.8)",
      uppercase_field_names: true
    }]
  }, {
    title: "Australian collection",
    layers: [{
      title: "River basins",
      layers: "be10-denude:crn_aus_basins",
      polygonal: true,
      fields: CRN_BASINS_FIELDS,
      query_fields: CRN_QUERY_FIELDS,
      uppercase_field_names: true
    }, {
      title: "Sample sites",
      layers: "be10-denude:crn_aus_outlets",
      fields: CRN_OUTLETS_FIELDS,
      cluster: true,
      colour: "rgba(74, 20, 134, 0.8)",
      uppercase_field_names: true
    }]
  }, {
    title: "Large basins",
    layers: [{
      title: "River basins",
      layers: "be10-denude:crn_xxl_basins",
      polygonal: true,
      fields: CRN_BASINS_FIELDS,
      query_fields: CRN_QUERY_FIELDS,
      uppercase_field_names: true
    }, {
      title: "Sample sites",
      layers: "be10-denude:crn_xxl_outlets",
      fields: CRN_OUTLETS_FIELDS,
      cluster: true,
      colour: "rgba(74, 20, 134, 0.8)",
      uppercase_field_names: true
    }]
  }, {
    title: "UOW (in preparation)",
    layers: [{
      title: "River basins",
      layers: "be10-denude:crn_inprep_basins",
      polygonal: true,
      fields: CRN_BASINS_FIELDS,
      query_fields: CRN_QUERY_FIELDS,
      uppercase_field_names: true
    }, {
      title: "Sample sites",
      layers: "be10-denude:crn_inprep_outlets",
      fields: CRN_OUTLETS_FIELDS,
      cluster: true,
      colour: "rgba(8, 82, 156, 0.8)",
      uppercase_field_names: true
    }]
  }]
}, {
  title: "Sedimentary Archives",
  colour: "rgba(69, 90, 100, 0.5)",
  layers: [{
    title: "Fluvial deposits",
    layers: [{
      title: "OSL collection",
      layers: "be10-denude:sahulsed_fluvial_osl",
      fields: SAHUL_OSL_FIELDS,
      query_fields: SAHULSED_FLV_OSL_QUERY_FIELDS,
      cluster: true,
      colour: "rgba(42, 187, 155, 0.8)",
      uppercase_field_names: true
    }, {
      title: "TL collection",
      layers: "be10-denude:sahulsed_fluvial_tl",
      fields: SAHUL_TL_FIELDS,
      query_fields: SAHULSED_FLV_TL_QUERY_FIELDS,
      cluster: true,
      colour: "rgba(42, 241, 195, 0.8)",
      uppercase_field_names: true
    }]
  }, {
    title: "Aeolian deposits",
    layers: [{
      title: "OSL collection",
      layers: "be10-denude:sahulsed_aeolian_osl",
      fields: SAHUL_OSL_FIELDS,
      query_fields: SAHULSED_AEN_OSL_QUERY_FIELDS,
      cluster: true,
      colour: "rgba(38, 166, 91, 0.8)",
      uppercase_field_names: true
    }, {
      title: "TL collection",
      layers: "be10-denude:sahulsed_aeolian_tl",
      fields: SAHUL_TL_FIELDS,
      query_fields: SAHULSED_AEN_TL_QUERY_FIELDS,
      cluster: true,
      colour: "rgba(0, 230, 64, 0.8)",
      uppercase_field_names: true
    }]
  }, {
    title: "Lacustrine deposits",
    layers: [{
      title: "OSL collection",
      layers: "be10-denude:sahulsed_lacustrine_osl",
      fields: SAHUL_OSL_FIELDS,
      query_fields: SAHULSED_LAC_OSL_QUERY_FIELDS,
      cluster: true,
      colour: "rgba(145, 180, 150, 0.8)",
      uppercase_field_names: true
    }, {
      title: "TL collection",
      layers: "be10-denude:sahulsed_lacustrine_tl",
      fields: SAHUL_TL_FIELDS,
      query_fields: SAHULSED_LAC_TL_QUERY_FIELDS,
      cluster: true,
      colour: "rgba(144, 198, 149, 0.8)",
      uppercase_field_names: true
    }]
  }, {
    title: "Coastal deposits",
    layers: [{
      title: "OSL collection",
      layers: "be10-denude:sahulsed_coastal_osl",
      fields: SAHUL_OSL_FIELDS,
      query_fields: SAHULSED_CST_OSL_QUERY_FIELDS,
      cluster: true,
      colour: "rgba(79, 120, 119, 0.8)",
      uppercase_field_names: true
    }, {
      title: "TL collection",
      layers: "be10-denude:sahulsed_coastal_tl",
      fields: SAHUL_TL_FIELDS,
      query_fields: SAHULSED_CST_TL_QUERY_FIELDS,
      cluster: true,
      colour: "rgba(136, 179, 178, 0.8)",
      uppercase_field_names: true
    }]
  }]
}, {
  title: "Sahul Archaeology",
  colour: "rgba(69, 90, 100, 0.5)",
  layers: [{
    title: "OSL collection",
    layers: "be10-denude:sahularch_osl",
    fields: SAHUL_OSL_FIELDS,
    query_fields: SAHULARCH_OSL_QUERY_FIELDS,
    polygonal: true,
    cluster: true,
    colour: "rgba(241, 196, 15, 0.8)",
    uppercase_field_names: true
  }, {
    title: "TL collection",
    layers: "be10-denude:sahularch_tl",
    fields: SAHUL_TL_FIELDS,
    query_fields: SAHULARCH_TL_QUERY_FIELDS,
    polygonal: true,
    cluster: true,
    colour: "rgba(243, 156, 18, 0.8)",
    uppercase_field_names: true
  }, {
    title: "Radiocarbon collection",
    layers: "be10-denude:sahularch_c14",
    fields: SAHULARCH_RADIOCARBON_FIELDS,
    query_fields: SAHULARCH_RADIOCARBON_QUERY_FIELDS,
    polygonal: true,
    cluster: true,
    colour: "rgba(211, 84, 0, 0.8)",
    uppercase_field_names: true
  }]
}, {
  title: "Paleoenvironments",
  colour: "rgba(69, 90, 100, 0.5)",
  layers: [{
    title: "Sedimentary Charcoal (collection units)",
    layers: "be10-denude:sahulchar_units",
    fields: SAHULCHAR_FIELDS,
    query_fields: SAHULCHAR_QUERY_FIELDS,
    cluster: true,
    colour: "rgba(169, 50, 38, 0.8)",
    uppercase_field_names: true 
  }, {
    title: "IPPD: Indo-Pacific Pollen (datasets level)",
    layers: "be10-denude:ippd_datasets",
    fields: IPPD_FIELDS,
    query_fields: IPPD_QUERY_FIELDS,
    cluster: true,
    colour: "rgba(75, 58, 38, 0.8)",
    uppercase_field_names: true    
  }]
}, {
  title: "Partner Collections",
  colour: "rgba(69, 90, 100, 0.5)",
  layers: [{
    title: "FosSahul database",
    layers: "be10-denude:fossahul_webmercator_nrand_25000",
    fields: FOSSAHUL_FIELDS,
    query_fields: FOSSAHUL_QUERY_FIELDS,
    polygonal: true,
    cluster: true,
    colour: "rgba(128, 128, 0, 0.8)",
    uppercase_field_names: true
  }, {
    title: "ExpAge database",
    layers: "be10-denude:expage",
    fields: EXPAGE_FIELDS,
    query_fields: EXPAGE_QUERY_FIELDS,
    cluster: true,
    colour: "rgba(52, 73, 94, 0.8)",
    uppercase_field_names: true
  }]
}];

export const INITIAL_CLUSTER_DISTANCE = 25;
export const INITIAL_CLUSTERING_ENABLED = true;

export const PROJECTION = "EPSG:900913";

export const GEOSERVER_URL = `${process.env.DOMAIN}/geoserver/`;
export const CGI_URL = `${process.env.DOMAIN}/cgi-bin/`;

export const WFS_FEATURE_COUNT = 100;
export const WMS_URL = `${GEOSERVER_URL}wms`;
export const WFS_URL = `${GEOSERVER_URL}wfs`;

export const EXTRA_WMS_SOURCE_PARAMS = {
  ...(process.env.DOMAIN && process.env.DOMAIN.indexOf("localhost") >= 0
    ? {
        crossOrigin: "anonymous",
      }
    : {}),
};
